<template>
  <v-card class="pa-3 mx-auto py-6 my-16" max-width="500">
    <v-card-title>
      <v-divider class="my-1"></v-divider>
      <div class="mx-2">Forgot Password</div>
      <v-divider class="my-1"></v-divider>
    </v-card-title>
    <v-card-text v-if="step === 1">
      <div class="mb-10 text-body-1 green--text text--darken-2 ">Enter your email address below.
        <br/>
        <br/>
        A password reset link will be sent to your inbox.
      </div>
      <v-form ref="form">
        <v-text-field label="Email Address" outlined  v-model="email" :rules="emailRules"></v-text-field>
      </v-form>
      <v-btn block class="success" x-large @click="createPasswordResetRequest">Get Link</v-btn>
      <v-btn class="pa-10" block text @click="cancel">Cancel</v-btn>
    </v-card-text>
    <v-card-text v-if="step === 2">
        <span class="text-h5 text-body-1 green--text text--darken-2" >A password reset link has been sent to your inbox.</span>
    </v-card-text>
  </v-card>
</template>

<script>
import httpClient from "@/plugins/axios";

export default {
  name: "ForgotPassword",
  data(){
    return{
      email:'',
      step: 1,
      emailRules: [
        v => !!v || 'Email is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Email must be valid',
      ]
    }
  },
  methods:{
    createPasswordResetRequest(){
      const vm = this;
      httpClient.post('/account/forgot-password', {email: vm.email })
      .then(function(response){
          vm.step = 2;
      });
    },
    cancel(){
      this.$router.push('/login');
    }
  }
}
</script>

<style scoped>

</style>
