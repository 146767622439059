<template>



    <v-card class="pa-3 mx-auto py-6 my-16" max-width="500">
      <v-card-title>
        <v-divider class="my-1"></v-divider>
        <div class="mx-2">Change Your Password</div>
        <v-divider class="my-1"></v-divider>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field label="Old Password" outlined type="password"  v-model="passwords.oldPassword" :rules="[rules.passwordRequired, validateOldPassword]"></v-text-field>
          <v-text-field label="New Password" outlined type="password" v-model="passwords.newPassword" :rules="[rules.passwordRequired]"></v-text-field>
          <v-text-field label="Confirm New Password" type="password" outlined  v-model="passwords.confirmNewPassword" :rules="[rules.passwordRequired, passwordConfirmationRule]"></v-text-field>
        </v-form>
        <v-btn block class="success" x-large @click="changePassword">Change Password</v-btn>
        <v-btn class="pa-10" block text @click="cancel">Cancel</v-btn>
      </v-card-text>
    </v-card>

</template>

<script>
import httpClient from "@/plugins/axios";
import {mapGetters} from "vuex";

export default {
  name: "ChangePassword",
  computed:{
    ...mapGetters({
      isAdmin: 'app/isAdmin'
    }),
    passwordConfirmationRule() {
      return () => (this.passwords.newPassword === this.passwords.confirmNewPassword) || 'Passwords must match'
    }
  },
  data(){
    return{
      passwords:{
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      oldPasswordValid: true,
      valid: true,
      rules: {
        passwordRequired: v => !!v || 'Please enter your password',
      }
    }
  },
  methods:{
    validateOldPassword(){
      return this.oldPasswordValid ? true : 'Old Password Incorrect';
    },
    cancel(){
      const vm = this;
      if(vm.isAdmin){
        vm.$router.push('/admin');
      }else{
        vm.$router.push('/');
      }
    },
    changePassword(){
      const vm = this;
      httpClient.post('/account/change-password', vm.passwords )
        .then(function(response){
          if(response.data.success){
            vm.$root.$emit('saveSuccess', {message: 'Your password was changed'})

            if(vm.isAdmin){
              vm.$router.push('/admin');
            }else{
              vm.$router.push('/');
            }
          }else{
            //better experiecne would be to add oldpassword error to response and trigger validation
            if(response.data.oldPasswordInvalid){
              vm.oldPasswordValid = false;
              vm.$refs.form.validate();
            }else{
              let failMessage = response.data.message || 'Unable to change your password, please try again.';
              vm.$root.$emit('saveFailed', {message:failMessage});
            }

          }
          //redirct to home, throw up success message
          //use store and isadmin to decide where to send them

      })
        .catch(function(error){
          vm.$root.$emit('saveFailed', {message:'Unable to change your password, please try again.'})
      });
    }
  }
}
</script>

<style scoped>

</style>
