<template>


    <v-card class="pa-3 mx-auto my-16" max-width="500">
      <v-card-title>
        <v-divider class="my-1"></v-divider>
        <div class="mx-2">Sign In</div>
        <v-divider class="my-1"></v-divider>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="login">
        <v-text-field label="Email" outlined v-model="email" :rules="[rules.emailRequired, validLogin]"></v-text-field>
        <v-text-field label="Password" outlined  v-model="password" type="password" :rules="[rules.passwordRequired]"></v-text-field>
        <v-checkbox dense label="Remember me"></v-checkbox>
          <v-btn block class="success" x-large  type="submit">Sign In</v-btn>
        </v-form>

        <div class="text-center mt-2 text-body-2">
          <router-link to="/forgot-password">Forgot your password?</router-link>
        </div>

        <div class="text-overline text-uppercase mt-3 text-center">
          <v-responsive max-width="280" class="mx-auto">
            By signing in, you agree to the
            <a href="#">Terms of Service</a> & <a href="#">Privacy Policy</a>
          </v-responsive>
        </div>
      </v-card-text>
    </v-card>

</template>


<script>
import httpClient from '@/plugins/axios'
import store from '@/store'
import {mapGetters} from "vuex";
export default {
  name: "login",
  data(){
    return {
      email: '',
      password: '',
      valid: true,
      rules: {
        emailRequired: v => !!v || 'Please enter your email address',
        passwordRequired: v => !!v || 'Please enter your password'
      }
    }
  },
  computed:{
    ...mapGetters({
      isAdmin: 'app/isAdmin'
    }),
  },
  methods: {
    validLogin(){
      const vm = this;
      return vm.valid ? true : 'Invalid Email Address or Password';
    },
    login(){
      const vm = this;
      vm.valid = true;
      this.$refs.form.validate();
      store.dispatch('app/login', {email: vm.email, password: vm.password})
      .then(function(response){

        if(!response.success){
          vm.valid = false;
          vm.$refs.form.validate();
        }
        else if (response.passwordChangeRequired){
          vm.$router.push('/change-password');
        }
        else{
          if(response.role.toLowerCase() === 'admin'){
            vm.$router.push('/admin');
          }else{
            vm.$router.push('/');
          }
        }



      })
    }
  }
}
</script>

<style scoped>

</style>
