<template>
  <v-card class="pa-3 mx-auto py-6 my-16" max-width="500">
    <v-card-title>
      <v-divider class="my-1"></v-divider>
      <div class="mx-2">Reset Your Password</div>
      <v-divider class="my-1"></v-divider>
    </v-card-title>
    <v-card-text v-if="step === 1">
      <v-form ref="form">
        <v-text-field label="New Password" outlined  v-model="passwords.newPassword" :rules="[rules.passwordRequired]"></v-text-field>
        <v-text-field label="Confirm New Password" outlined  v-model="passwords.confirmNewPassword" :rules="[rules.passwordRequired, passwordConfirmationRule]"></v-text-field>
      </v-form>
      <v-btn block class="success" x-large @click="changePassword">Reset Password</v-btn>
    </v-card-text>
    <v-card-text v-if="step === 2">
      <div class="text-center text-h5 text-body-1 red--text text--darken-2 mb-15" >Your password link is expired or invalid.  Please click below to try again</div>
      <div class="text-center mt-2 text-body-1">
        <router-link to="/forgot-password">Forgot Password</router-link>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import httpClient from "@/plugins/axios";
export default {
  name: "ResetPassword",
  computed:{
    passwordConfirmationRule() {
      return () => (this.passwords.newPassword === this.passwords.confirmNewPassword) || 'Passwords must match'
    }
  },
  data(){
    return{
      passwords:{
        newPassword: '',
        confirmNewPassword: '',
      },
      step: 1,
      valid: true,
      rules: {
        passwordRequired: v => !!v || 'Please enter your password',
      }
    }
  },
  methods:{
    changePassword(){
      const id = this.$route.params.id;
      const vm = this;
      httpClient.post(`/account/${id}/reset-password`, vm.passwords )
        .then(function(response){
          if(response.data.success){
            vm.$router.push('/login');
          }else{
            vm.step = 2;
          }
        })
        .catch(function(error){
          vm.$root.$emit('saveFailed', {message:'Unable to change your password, please try again.'})
        });
    }
  }
}
</script>

<style scoped>

</style>
